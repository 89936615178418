import { gql } from "@apollo/client";

export const GET_PARTNER_DETAILS = gql`
  query GetPartnerDetails($id: String!) {
    partnerDetails(id: $id) {
      name
    }
  }
`;

/**
 * Query to fetch all partners
 * @param $pagination Pagination object
 * @param $filter Filter object
 * @returns Partners data
 *
 * Was used to fill homepage, has been removed and is not longer in use
 */
const GET_PARTNERS = gql`
  query GetAllPartners($pagination: Pagination, $filter: Filter) {
    partners(pagination: $pagination, filter: $filter) {
      pageInfo {
        currentPage
        totalPages
      }
      totalCount
      rows {
        name
        partnerUrl
        Enrollments {
          rowModifiedDatetime
        }
        Users {
          createdAt
        }
      }
    }
  }
`;
